import { reactive, ref } from 'vue'
import { check } from '@/shared/constants/icons.js'

export function useCapabilityUtils() {
  const namespaces = ref(new Set())
  const filtered = ref([])
  const foldAll = ref(false)
  const options = reactive({
    namespace: '',
    filter: ''
  })
  const successToast = reactive({
    showClose: true,
    icon: check,
    appearance: 'success',
    message: 'Successfully Updated Capability.',
    show: true
  })

  const errorToast = reactive({
    showClose: true,
    icon: 'alert-exclamation-circle',
    appearance: 'warning',
    message: '',
    show: true
  })

  const defaultMapper = (item) => item.title

  const getFilteredByNamespace = (list, propertyMapper) => {
    const mapper = propertyMapper || defaultMapper
    let newList = []
    if (namespaces.value.size) {
      newList = list.filter((item) => {
        return mapper(item).toLowerCase() === options.namespace.toLowerCase()
      })
    }
    return newList
  }

  const setNamespaces = (list, propertyMapper) => {
    const mapper = propertyMapper || defaultMapper
    namespaces.value = new Set()
    if (list && list.length) {
      list.map((item) => {
        const namespace = mapper(item)
        namespaces.value.add(namespace)
      })
      if (options.namespace === '' && namespaces.value.size) {
        options.namespace = Array.from(namespaces.value)[0]
      }
    }
  }

  const getAppliedFilters = (list, propertyMapper) => {
    const mapper = propertyMapper || defaultMapper
    let newList = list

    if (options.filter !== '') {
      const filter = options.filter.toLowerCase()
      newList = list.filter((item) => {
        return mapper(item).toLowerCase().includes(filter)
      })
    }
    if (newList.length) {
      setNamespaces(newList, mapper)
    } else {
      setNamespaces(list, mapper)
      options.filter = ''
    }
    if (options.namespace !== '') {
      newList = getFilteredByNamespace(list, mapper)
    }

    return newList
  }

  const getFilteredByQuery = (list, query, propertyMapper) => {
    const mapper = propertyMapper || defaultMapper
    const cleanupQuery = query.replace(/\s/g, '')
    return list.filter((item) => {
      return mapper(item).toLowerCase().includes(cleanupQuery.toLowerCase())
    })
  }

  return {
    namespaces,
    options,
    filtered,
    foldAll,
    successToast,
    errorToast,
    getFilteredByNamespace,
    setNamespaces,
    getAppliedFilters,
    getFilteredByQuery
  }
}
