<template>
  <BaseRuleModal
    :options="options"
    :is-save-enabled="isSaveEnabled"
    :error="error"
    class="type-id-modal"
    @did-save="save"
    @did-remove="remove"
    @did-close="close"
    @did-toggle-deleting="handleToggleDeletion"
  >
    <template #default="slotProps">
      <pn-input
        v-if="isComplex || options.isLoading || slotProps.isDeleting"
        disabled
        label="Type ID"
        :value="draft.key"
      />
      <pn-select
        v-else
        :key="draft.key"
        label="Type ID"
        @selectchange="draft.key = parseInt($event.detail)"
      >
        <pn-option
          v-for="(item, index) in typeIdsOfCountry"
          :key="index"
          :value="item"
          :selected="draft.key === item"
        >
          {{ item }} - {{ getTypeIdDescription(item) }}
        </pn-option>
      </pn-select>
      <RuleValueControl
        :value="draft.value"
        :capability="options.capability"
        :disabled="isComplex || options.isLoading || slotProps.isDeleting"
        :is-active="options.show"
        @did-change="draft.value = $event"
        @did-validate="handleValueValidation"
      />
    </template>
  </BaseRuleModal>
</template>

<script>
import ruleModalMixin from './mixins/ruleModalMixin.js'
import { useRuleModal } from '@/composables/useRuleModal.js'
import { useValueTypeUtils } from '@/composables/useValueTypeUtils.js'
import { TYPE_IDS } from '@postnord/retail-constants'
import i18n from '@/i18n/i18n'

export default {
  name: 'RuleTypeIdModal',
  mixins: [ruleModalMixin],
  setup(props, context) {
    const { draft, didMadeEdits, error, save, close, remove } = useRuleModal(
      props,
      context
    )
    const { checkIfComplex, checkIfBasicArray } = useValueTypeUtils()
    return {
      draft,
      didMadeEdits,
      error,
      checkIfComplex,
      checkIfBasicArray,
      save,
      close,
      remove
    }
  },
  data() {
    return {
      typeIds: TYPE_IDS
    }
  },
  computed: {
    typeIdsOfCountry() {
      let list = Object.keys(this.typeIds)
        .filter((id) => {
          const item = this.typeIds[id]

          return item.country.includes(this.options.country)
        })
        .map((id) => this.typeIds[id].id)
      if (this.draft.key !== '' && !list.includes(this.draft.key)) {
        list = [...list, this.draft.key].sort()
      }
      return list
    },
    isSaveEnabled() {
      return (
        this.options.allowSave &&
        this.error === '' &&
        this.draft.key !== '' &&
        this.didMadeEdits
      )
    }
  },
  methods: {
    getKeyById(targetId) {
      const entry = Object.entries(this.typeIds).find(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        ([key, value]) => value.id === targetId
      )
      return entry ? entry[0] : undefined
    },

    getTypeIdDescription(item) {
      return this.getKeyById(item)
        ? i18n.global.t(`CAPABILITIES.${this.getKeyById(item)}`)
        : ''
    }
  }
}
</script>
