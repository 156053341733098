import {
  applyPolyfills,
  defineCustomElements
} from '@postnord/web-components/loader'

import { createApp } from 'vue'
import { createRouter } from './router/index.js'
import store from './store'

import App from './App.vue'
import i18n from './i18n/i18n.js'
import PnRetailBackofficeComponents from '@postnord/retail-backoffice-components/dist'

import 'flag-icon-css/css/flag-icons.min.css'
import initializeFaro from './initialize-faro.js'

applyPolyfills().then(() => {
  defineCustomElements()
})
initializeFaro()
const router = createRouter()

const app = createApp(App)
  .use(router)
  .use(store)
  .use(i18n)
  .use(PnRetailBackofficeComponents)

app.mount('#app')
