import { getWebInstrumentations, initializeFaro } from '@grafana/faro-web-sdk'
import { TracingInstrumentation } from '@grafana/faro-web-tracing'
export default () =>
  initializeFaro({
    url: process.env.VUE_APP_FARO_COLLECTOR_URL,
    app: {
      name: 'Retail portal capabilities',
      version: '1.0.0',
      environment: 'production'
    },
    instrumentations: [
      // Mandatory, omits default instrumentations otherwise.
      ...getWebInstrumentations(),
      // Tracing package to get end-to-end visibility for HTTP requests.
      new TracingInstrumentation()
    ]
  })
